<template>
  <div>
      <div>
    <div class="flex flex-col flex-wrap md:flex-row bg-white overflow-hidden">

    <div class="order-4 highlight w-full">
    <div class="relative z-10 pb-8 sm:pb-16 md:max-w-2xl md:w-full md:pb-28 mx-auto">
      <div class="mt-0 max-w-screen-xl px-4">
      <div class="max-w-screen-xl mx-auto">
      <img src="../assets/images/DemoDiceLogo512.png" class="w-40">
      <h1 class="text-3xl mt-4">DemoDice Privacy Policy</h1>
      <p>The DemoDice app does not collect, transmit, retain, or share any personal user data.</p> 
      <p>This app was developed by Curious Bird AB as part of the Demogames project. You can submit inquires about this policy to chirp@curiousbird.se</p>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
  </div>
</template>

<script>


export default {
  name: 'home',

}
</script>